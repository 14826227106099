import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXTwitter } from '@fortawesome/free-brands-svg-icons';
import comic from '../assets/bus_stop_gag.png'

export function Homepage() {
    return <div className="flex flex-col items-center justify-center columns-1">
        <h1 className='font-mono text-4xl p-10'>Strange Tamaru</h1>
        <h1 className='font-mono text-2xl p-10'>Website in construction! Enjoy the only comic pubished here so far!</h1>
        <img src={comic} alt="comic" className="w-auto"/>
        <h1 className='font-mono text-2xl p-10'>Here are my socials if you're interested</h1>
        <a href='https://x.com/StrangeTamaru'>
            <FontAwesomeIcon icon={faXTwitter} className='fa-5x p-5 hover:text-green-500 hover:cursor-pointer' />
        </a>
    </div>
}